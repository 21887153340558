import { SessionStatus } from '../kit/utils/constants';
import {
    accountRepository,
    languageRepository,
    storageConfigurationRepository,
    confirmationsRepository,
    confirmationCategoryRepository,
    configurationRepository,
    globalClientCompanyRepository,
    engagementThirdPartyCompanyRepository,
    organizationClustersRepository
} from '../kit/repository';
import { createToast, getToastType } from '../components/toast/Toast';
import { withSuccessResult } from './BaseService';

const batchfetchFunctions = async customsFunctions => {
    const promisesFunctions = customsFunctions.map(async myFunction => await myFunction());
    return await Promise.all(promisesFunctions);
};

export const fetchData = async (myThis, customsFunctions) => {
    myThis.setState({
        loading: true
    });

    const checkSessionsResults = await batchfetchFunctions(customsFunctions);

    checkSessionsResults.some((result, index) => {
        if (myThis.state.checkSessionResults[index]?.displayMessage && result && result !== SessionStatus.valid) {
            myThis.props.addToastMessage(
                createToast(
                    getToastType(result),
                    myThis.state.checkSessionResults[index]?.errorMessage ??
                        myThis.props.intl.formatMessage({ id: 'DEFAULT_ERROR_MESSAGE.TITLE' }),
                    myThis.state.checkSessionResults[index]?.technicalMessage ??
                        myThis.props.intl.formatMessage({ id: 'DEFAULT_ERROR_MESSAGE.MESSAGE' })
                )
            );
        }
        return null;
    });

    myThis.setState({
        loading: false
    });
};

export const fetchDataFunctional = async (setLoading, checkSessionResults, addToastMessage, intl, customsFunctions) => {
    setLoading(true);

    const checkSessionsResults = await batchfetchFunctions(customsFunctions);

    checkSessionsResults.some((result, index) => {
        if (checkSessionResults[index]?.displayMessage && result && result !== SessionStatus.valid) {
            addToastMessage(
                createToast(
                    getToastType(result),
                    checkSessionResults[index]?.errorMessage ??
                        intl.formatMessage({ id: 'DEFAULT_ERROR_MESSAGE.TITLE' }),
                    checkSessionResults[index]?.technicalMessage ??
                        intl.formatMessage({ id: 'DEFAULT_ERROR_MESSAGE.MESSAGE' })
                )
            );
        }
        return null;
    });

    setLoading(false);
}

const performFetchUserProfile = async (
    myThis,
    isStandAlone = false,
    { checkSession, saveProfile, logoutUser }
) => {
    const result = await accountRepository.fetchUserInfo();
    const sessionStatus = await checkSession(result);

    myThis.setState(previousState => ({
        checkSessionResults: [
            ...previousState.checkSessionResults,
            {
                sessionStatus: sessionStatus,
                technicalMessage: result?.error
            }
        ]
    }));

    withSuccessResult(myThis, sessionStatus, result, () => saveProfile(result.parsedValue), logoutUser);

    return isStandAlone
        ? {
              sessionStatus: sessionStatus,
              technicalMessage: result?.error,
              status: result.status
          }
        : sessionStatus;
};

/**
 * Get detailed profile for connected user.
 * @public
 */
export const fetchUserProfile = async (myThis, isStandAlone = false) => {
    return await performFetchUserProfile(myThis, isStandAlone, {
        ...myThis.props,
    });
};

/**
 * Get the list of documents for the confirmation selected.
 * @public
 */
export const fetchConfirmationDocuments = async (myThis, filters = {}) => {
    const {
        intl,
        match: { params },
        checkSession
    } = myThis.props;
    const result = await confirmationsRepository.fetchDocuments(params.confirmationId, filters);
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            paginatedConfirmationItems: result.parsedValue
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    sessionStatus: result.status,
                    technicalMessage: result?.error,
                    errorMessage: intl.formatMessage({ id: 'CONFIRMATION.ACCESS.DOCUMENTS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

/**
 * Get all client companies available for the counry.
 * @public
 */
export const fetchCountryClients = async (myThis, filters = {}) => {
    const { intl, checkSession } = myThis.props;

    const result = await globalClientCompanyRepository.fetchAll(filters);
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            clientCompaniesPaginated: result.parsedValue
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    sessionStatus: result.status,
                    technicalMessage: result?.error,
                    errorMessage: intl.formatMessage({ id: 'FETCH.COMPANY.CLIENTS.ACCESS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

/**
 * Get third-party companies available.
 * @public
 */
export const fetchThirdParties = async (myThis, engagementId) => {
    const { intl, checkSession } = myThis.props;
    const result = await engagementThirdPartyCompanyRepository.fetchByEngagement(engagementId);
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            thirdPartyCompanies: result?.parsedValue?.items ? Object.values(result.parsedValue?.items) : []
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    sessionStatus: result.status,
                    technicalMessage: result?.error,
                    errorMessage: intl.formatMessage({
                        id: 'FETCH.COMPANY.THIRDPARTIES.ACCESS.ERROR'
                    })
                }
            ]
        }
    );

    return sessionStatus;
};

/**
 * Get types available for confirmations.
 * @public
 */
export const fetchConfirmationCategories = async myThis => {
    const { intl, checkSession } = myThis.props;
    const result = await confirmationCategoryRepository.fetchAll();
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            confirmationCategories: Object.values(result.parsedValue)
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    sessionStatus: result?.status,
                    technicalMessage: result?.error,
                    errorMessage: intl.formatMessage({ id: 'FETCH.CONFIRMATION.TYPE.ACCESS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

/**
 * Get list of countries.
 * @public
 */
export const fetchClusters = async (myThis, errorMessage) => {
    const { intl, saveClusters } = myThis.props;
    const result = await organizationClustersRepository.fetchAll();
    const sessionStatus = result?.status === 200 ? SessionStatus.valid : SessionStatus.invalid;

    withSuccessResult(myThis, sessionStatus, result, () => saveClusters(result.parsedValue), {
        checkSessionResults: [
            ...myThis.state.checkSessionResults,
            {
                sessionStatus: result?.status,
                technicalMessage: result?.error,
                errorMessage: errorMessage ?? intl.formatMessage({ id: 'CONFIRMATION.ACCESS.COUNTRIES.ERROR' })
            }
        ]
    });

    return sessionStatus;
};

/**
 * Get list of languages.
 * @public
 */
export const fetchLanguages = async (myThis, errorMessage) => {
    const { intl, checkSession, saveLanguages } = myThis.props;

    const result = await languageRepository.fetchAll();
    const sessionStatus = await checkSession(result);

    withSuccessResult(myThis, sessionStatus, result, () => saveLanguages(result.parsedValue), {
        checkSessionResults: [
            ...myThis.state.checkSessionResults,
            {
                sessionStatus: result?.status,
                technicalMessage: result?.error,
                errorMessage: errorMessage ?? intl.formatMessage({ id: 'CONFIRMATION.ACCESS.LANGUAGES.ERROR' })
            }
        ]
    });

    return sessionStatus;
};

/**
 * Get configuration checks.
 * @public
 */
export const fetchConfigurationChecks = async (myThis, filters = {}) => {
    const { intl, checkSession } = myThis.props;

    const result = await configurationRepository.getAll(filters);
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            configurationsChecks: result?.parsedValue
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    sessionStatus: result?.status,
                    technicalMessage: result?.error,
                    errorMessage: intl.formatMessage({ id: 'FETCH.CONFIG.CHECKS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

/**
 * Get storage configurations available.
 * @public
 */
export const fetchStorage = async (myThis, errorMessage, displayMessage = true) => {
    const { intl, user, checkSession } = myThis.props;

    const result = await storageConfigurationRepository.fetchCountry(user.clusterId);
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            storageConfigurations: Object.values(result.parsedValue)
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    displayMessage: displayMessage,
                    sessionStatus: result?.status,
                    technicalMessage: result?.error,
                    errorMessage: errorMessage ?? intl.formatMessage({ id: 'FETCH.STORAGE.CONFIGURATION.ACCESS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

/**
 * Get default storage configuration available.
 * @public
 */
export const fetchStorageDefault = async (myThis, errorMessage, displayMessage = true) => {
    const { intl, user, checkSession } = myThis.props;

    const result = await storageConfigurationRepository.fetchDefaultCountry(user.clusterId);
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            storageDefaultConfiguration: result.parsedValue
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,

                {
                    displayMessage: displayMessage,
                    sessionStatus: result?.status,
                    technicalMessage: result?.error,
                    errorMessage: errorMessage ?? intl.formatMessage({ id: 'FETCH.STORAGE.CONFIGURATION.ACCESS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

/**
 * Get SharePoint Online storage configuration available.
 * @public
 */
export const fetchStorageSharePointOnline = async (myThis, errorMessage, displayMessage = true) => {
    const { intl, user, checkSession } = myThis.props;

    const result = await storageConfigurationRepository.fetchSharePointOnlineCountry(user.clusterId);
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            storageSharePointOnlineConfiguration: result.parsedValue
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    displayMessage: displayMessage,
                    sessionStatus: result?.status,
                    technicalMessage: result?.error,
                    errorMessage: errorMessage ?? intl.formatMessage({ id: 'FETCH.STORAGE.CONFIGURATION.ACCESS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};
