import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';

export default function useLazyQuery (key, fn, onSuccess, options = {}) {
    const [enabled,setEnabled] = useState(false)
    const query = useQuery({
      queryKey: key,
      queryFn: fn,
      ...options,
      enabled
    })

    useEffect(() => {
      if (query.data && !query.error) {
        onSuccess(query.data);
      }
    }, [query.data]);
  
    return { call: useCallback(() => setEnabled(true), []), query }
  }