import React, { useEffect, useRef, useState, useCallback } from 'react';
import { INIT_ENGAGEMENT_FILTER, INIT_ITEMS_FILTER, SORT_CHOICES } from '../../kit/utils/constants';
import { ENGAGEMENT_COLUMNS_FILTERS } from '../../components/engagement/InitEngagement';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { injectIntl } from 'react-intl';
import { withCheckSession, withEngagement } from '../../enhancers';
import {
    CONFIRMATION_COLUMNS_FILTERS,
    INIT_MENU_CONFIRMATION_FILTERS
} from '../../components/confirmation/common';
import { PROFILE } from '../../types/types';
import { fetchEngagementConfirmations, ConfirmationService, ItemService } from '../../services';
import { fetchDataFunctional } from '../../services/FetchHelper';
import { saveDownloadedFile } from '../../helpers';
import * as engagementService from '../../services/EngagementService';
import { EngagementContainer } from '../../components/engagement';
import { DocumentPendingComponent } from '../../components/document';
import PropTypes from 'prop-types';
import * as actions from '../../store/actions';
import './engagement.scss';
import useLazyQuery from '../../hooks/useLazyQuery';

/***
 * Clean store filters in case the engagement has been changed
 * @param storedFilters: stored filters in redux
 * @param currentEngagementId: the current displayed engagement
 * @param cleanFilters: method that flushed the stored filters
 * @return the updated filters
 */
const getCleanedFilters = (storedFilters, currentEngagementId, cleanFilters) => {
    let confirmationFilters = { ...storedFilters };

    if (!!storedFilters.engagementId && storedFilters.engagementId !== currentEngagementId) {
        cleanFilters();
        confirmationFilters = {};
    }

    return confirmationFilters;
};

interface AuditorEngagementComponentProps {
    currentClusterId: any;
    engagementId: any;
    reduxConfirmationsFilters: any;
    flushConfirmationsFilters: any;
    intl: any;
    user: any;
    checkSession: any;
    addToastMessage: any;
    persistConfirmationsFilters: any;
    performDeleteEngagement: any;
    performToggleArchiveEngagement: any;
    performDownloadEngagement: any;
    getDownloadEngagementOptions: any;
    resetActiveStepPosition: any;
    engagement: any;
    confirmationCategories: any;
    workflowActions: any;
    saveSelectedConfirmationIndex: any;
}

const AuditorEngagementComponent = (props: AuditorEngagementComponentProps) => {
    const [state, setState] = useState<any>({
        checkSessionResults: [],
        menuEngagements: null, // list of engagements displayed in the engagement dropdown of pending documents
        menuConfirmationsFilters: {
            // for pending documents accordion
            ...INIT_MENU_CONFIRMATION_FILTERS,
            [CONFIRMATION_COLUMNS_FILTERS.CLUSTER_ID]: props.currentClusterId,
            [CONFIRMATION_COLUMNS_FILTERS.ENGAGEMENT_ID]: props.engagementId
        },
        paginatedItems: [], // pending items
        confirmationsFilters: {
            ...INIT_ENGAGEMENT_FILTER,
            engagementId: props.engagementId,
            isTrashed: false,
            ...getCleanedFilters(
                props.reduxConfirmationsFilters,
                props.engagementId,
                props.flushConfirmationsFilters
            )
        },
        itemsFilters: {
            userprofile: PROFILE.AUDITOR,
            pageSize: INIT_ITEMS_FILTER.PAGE_SIZE,
            pageIndex: INIT_ITEMS_FILTER.PAGE_INDEX,
            sort: SORT_CHOICES.UPDATED_DESC,
            displayMode: INIT_ITEMS_FILTER.DISPLAY_MODE_ROOT_ITEM_ONLY,
            engagementId: props.engagementId,
            confirmationId: INIT_ITEMS_FILTER.CONFIRMATION_ID,
            isLost: INIT_ITEMS_FILTER.IS_LOST
        },
        docTypesFilters: {
            userprofile: PROFILE.AUDITOR
        },
        loading: false
    });
    const setLoading = (isLoading: boolean) => {
        setState((prevState) => ({ ...prevState, loading: isLoading }))
    }

    const setErrorMessage = (errorMessage: any) => {
        setState((prevState) => ({ ...prevState, errorMessage }))
    }
    
    const fetchItems = async (filters) => {
        const result = await itemManagerRef.current?.fetch(filters);
        return result.parsedValue;
    }

    const onSuccessFetchItems = (data) => {
        setState((prevState) => ({ ...prevState, paginatedItems: data }));
    }

    const fetcher = useCallback(() => fetchItems(state.itemsFilters), [state.itemsFilters]);
    const { call: fetchItemsQuery } = useLazyQuery(['items', state.itemsFilters], fetcher, onSuccessFetchItems);
    
    const confirmationServiceRef = useRef<any>(ConfirmationService({
        intl: props.intl,
        user: props.user,
        checkSession: props.checkSession,
        addToastMessage: props.addToastMessage,
        setLoading: setLoading as any,
        callback: async isSuccess => {
            isSuccess && (await performFetchConfirmations());
        }
    }));

    const isFilteringMode = !!state?.confirmationsFilters[ENGAGEMENT_COLUMNS_FILTERS.CLIENT] ||
        !!state?.confirmationsFilters[ENGAGEMENT_COLUMNS_FILTERS.THIRD_PARTY] ||
        !!state?.confirmationsFilters[ENGAGEMENT_COLUMNS_FILTERS.CATEGORY] ||
        !!state?.confirmationsFilters[ENGAGEMENT_COLUMNS_FILTERS.WORKFLOW_ACTION];


    // ComponentDidMount translation
    useEffect(() => {
        async function componentDidMount () {
            const { docTypesFilters } = state;

            fetchItemsQuery();
            await fetchDataFunctional(setLoading, state.checkSessionResults, props.addToastMessage, props.intl, [
                () => performFetchConfirmations(),
                () => fetchDocumentTypes(docTypesFilters)
            ]);
        }
        componentDidMount();
    }, [])

    const itemManagerRef = useRef<any>(ItemService({
        intl: props.intl,
        user: props.user,
        checkSession: props.checkSession,
        addToastMessage: props.addToastMessage,
        callback: async (isSuccess, _data, _) => isSuccess && (fetchItemsQuery()),
        setLoading: setLoading,
        setErrorMessage: setErrorMessage as any
    }));

    // ComponentDidUpdate translation
    useEffect(() => {
        async function componentDidUpdate() {
            if (!state.loading) {
                await performFetchConfirmations();
                //save the filters to redux
                props.persistConfirmationsFilters(state.confirmationsFilters);
            }
        }
        componentDidUpdate();
    }, [state.confirmationsFilters]);

    useEffect(() => {
        async function componentDidUpdate() {
            if (!state.loading) {
                fetchItemsQuery();
            }
        }
        componentDidUpdate();
    }, [state.itemsFilters]);
    
    const downloadItem = async item => {
        await itemManagerRef.current?.download(item, (isSuccess, data, _) => isSuccess && saveDownloadedFile(data, item.name));
    };

    const updateItem = async item => {
        return await itemManagerRef.current?.update({ ...item, userProfile: PROFILE.AUDITOR });
    };

    const fetchDocumentTypes = async filters => {
        const result = await itemManagerRef.current?.getDocumentTypes(filters);
        if (result?.isSuccess) {
            setState((prevState) => ({ ...prevState, documentTypes: result?.body }));
        }
    };

    const handleConfirmationFilterChange = values => {
        setState((prevState) => ({ ...prevState, confirmationsFilters: { ...prevState.confirmationsFilters, ...values } }));
    };

    const handleItemsFilterChange = values => {
        setState((prevState) => ({ ...prevState, itemsFilters: { ...prevState.itemsFilters, ...values } }));
    };

    const performFetchConfirmations = async () => {
        const { intl, checkSession, addToastMessage, engagementId } = props;
        const { confirmationsFilters } = state;

        await fetchEngagementConfirmations({
            filters: confirmationsFilters,
            engagementId: engagementId,
            checkSession,
            addToastMessage,
            errorMessage: intl.formatMessage({ id: 'FETCH.ENGAGEMENT.CONFIRMATIONS.ERROR' }),
            setLoading: setLoading,
            callback: (isSuccess, data) => {
                if (isSuccess) {
                    setState((prevState) => ({ ...prevState, confirmationPaginated: data.parsedValue }));
                } else {
                    const checkSessionResults = [
                        ...state.checkSessionResults,
                        {
                            sessionStatus: data.status,
                            technicalMessage: data?.error,
                            errorMessage: intl.formatMessage({ id: 'FETCH.ENGAGEMENT.CONFIRMATIONS.ERROR' })
                        }
                    ];

                    setState((prevState) => ({ ...prevState, checkSessionResults: checkSessionResults }));
                }
            }
        });
    };

    const performDeletePermanentConfirmation = async confirmationInfo => {
        await confirmationServiceRef.current?.deletePermanently(confirmationInfo);
    };

    // Put the confirmation in the recycle bin or restore it
    const performTrashConfirmation = async (confirmationInfo, isTrashed) => {
            await confirmationServiceRef.current?.trash(confirmationInfo, isTrashed);
    };

    const handleDeleteEngagement = async engagementInfo => {
        const { performDeleteEngagement } = props;
        return performDeleteEngagement({
            engagementInfo: engagementInfo,
            setLoading: setLoading
        });
    };

    const handleArchiveEngagement = async ({ engagement: { id, archived } }) => {
        const { performToggleArchiveEngagement } = props;
        const result = await performToggleArchiveEngagement({
            engagementId: id,
            setLoading: setLoading,
            strings: {
                successMessageId: `FETCH.ENGAGEMENT.${!archived ? 'ARCHIVE' : 'UNARCHIVE'}.SUCCESS`,
                errorMessageId: `FETCH.ENGAGEMENT.${!archived ? 'ARCHIVE' : 'UNARCHIVE'}.ERROR`
            }
        });

        return result;
    };

    const handleDownloadEngagement = async (engagement, downloadType) => {
        const { performDownloadEngagement } = props;
        return performDownloadEngagement({
            downloadType: downloadType,
            engagementId: engagement.id,
            setLoading: setLoading,
            engagementName: engagement.name
        });
    };

    const handleFetchMenuEngagements = async () => {
        await engagementService.fetchMenuEngagementsFunctional(
            setState,
            state,
            props.intl,
            props.checkSession
        )
    };

    const fetchMenuConfirmations = async values => {
        const { currentClusterId } = props;
        const filters = { ...state.menuConfirmationsFilters, clusterId: currentClusterId, ...values };

        let menuConfirmations = [];
        await confirmationServiceRef.current?.fetchMenu(filters, (isSuccess, result) => {
            if (isSuccess) {
                menuConfirmations = result.parsedValue;
            }
        });

        setState((prevState) => ({ ...prevState, menuConfirmationsFilters: filters }));
        return menuConfirmations;
    };


    const {
        user,
        getDownloadEngagementOptions,
        resetActiveStepPosition,
        engagement,
        confirmationCategories,
        workflowActions,
        saveSelectedConfirmationIndex
    } = props;
    const { loading, confirmationPaginated, confirmationsFilters } = state;

    return (
        <>
            <DocumentPendingComponent
                engagementId={props.engagementId}
                shouldPopulateMenuConfirmations={!!props.engagementId}
                fetchMenuEngagement={handleFetchMenuEngagements}
                handleFetchMenuConfirmations={fetchMenuConfirmations}
                user={props.user}
                actionProfile={PROFILE.AUDITOR}
                loading={state.loading}
                paginatedItems={state.paginatedItems}
                menuEngagements={state.menuEngagements}
                documentTypes={state.documentTypes}
                fetchItemFile={itemManagerRef.current?.fetchFile}
                fetchItemInfo={itemManagerRef.current?.fetchInfo}
                updateItem={updateItem}
                downloadItem={downloadItem}
                handleFilterItems={handleItemsFilterChange}
            />

            <EngagementContainer
                saveSelectedConfirmationIndex={saveSelectedConfirmationIndex}
                isFilteringMode={isFilteringMode}
                user={user}
                deleteEngagement={handleDeleteEngagement}
                archiveEngagement={handleArchiveEngagement}
                deleteConfirmation={info => performTrashConfirmation(info, true)}
                restoreConfirmation={info => performTrashConfirmation(info, false)}
                eraseConfirmation={performDeletePermanentConfirmation}
                downloadEngagement={handleDownloadEngagement}
                getDownloadEngagementOptions={getDownloadEngagementOptions}
                resetActiveStepPosition={resetActiveStepPosition}
                loading={loading}
                engagement={engagement}
                confirmationPaginated={confirmationPaginated}
                filters={confirmationsFilters}
                handleFilterChange={handleConfirmationFilterChange}
                confirmationCategories={confirmationCategories}
                workflowActions={workflowActions}
            />
        </>
    );
}

AuditorEngagementComponent.propTypes = {
    user: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    paginatedItems: PropTypes.array,
    checkSessionResults: PropTypes.array
};

AuditorEngagementComponent.defaultProps = {
    loading: false
};

const mapDispatchToProps = dispatch => ({
    addToastMessage: message => {
        dispatch(actions.addToastMessage(message));
    },
    resetActiveStepPosition: () => {
        dispatch(actions.resetActiveStepPosition());
    },
    persistConfirmationsFilters: filters => {
        dispatch(actions.setConfirmationsFilters(filters));
    },
    flushConfirmationsFilters: () => {
        dispatch(actions.resetConfirmationsFilters());
    },
    saveSelectedConfirmationIndex: index => {
        dispatch(actions.setSelectedConfirmationIndex(index));
    }
});

const mapStateToProps = state => {
    return {
        user: state.account.user,
        reduxConfirmationsFilters: state.engagement.confirmationsFilters,
        currentClusterId: state.cluster.currentClusterId
    };
};

export const AuditorEngagement = compose(
    injectIntl,
    withCheckSession, // need to add this enhancer to check session when there api call on the page
    connect(mapStateToProps, mapDispatchToProps)
)(withEngagement(AuditorEngagementComponent));
