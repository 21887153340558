import { SessionStatus } from '../kit/utils/constants';
import { createToast, getToastType, TOAST_TYPE } from '../components/toast/Toast';

export const withSuccessResult = (myThis, sessionStatus, result, onSuccess, onError) => {
    if (sessionStatus === SessionStatus.valid && result.isSuccess) {
        if (typeof onSuccess === 'function') {
            return onSuccess();
        } else {
            return myThis.setState(onSuccess);
        }
    } else {
        if (typeof onError === 'function') {
            return onError();
        } else {
            return myThis.setState(onError);
        }
    }
};

export const withSuccessResultFunctional = (setState, sessionStatus, result, onSuccess, onError) => {
    if (sessionStatus === SessionStatus.valid && result.isSuccess) {
        if (typeof onSuccess === 'function') {
            return onSuccess();
        } else {
            return setState((prevState) => ({ ...prevState, ...onSuccess}));
        }
    } else {
        if (typeof onError === 'function') {
            return onError();
        } else {
            return setState((prevState) => ({ ...prevState, ...onError}));
        }
    }
};


export const handleResponseCompletion = (sessionStatus, isSuccess, onSuccess, onError) => {
    if (sessionStatus === SessionStatus.valid && isSuccess) {
        return onSuccess();
    } else {
        return onError();
    }
};

export const callAPI = async (
    apiMethodCall,
    { setLoading, checkSession, addToastMessage, errorMessage, successMessage, callback = _ => {} }
) => {
    try {
        setLoading(true);
        const result = await apiMethodCall();
        const sessionStatus = await checkSession(result);
        if (sessionStatus !== SessionStatus.invalid && result.isSuccess) {
            successMessage && addToastMessage(createToast(getToastType(sessionStatus), successMessage, result.error));
            callback && (await callback(true, result));
        } else {
            addToastMessage(createToast(getToastType(sessionStatus), errorMessage, result.error));
            callback && (await callback(false, result));
        }

        setLoading(false);
    } catch (error) {
        setLoading(false);
        addToastMessage(createToast(getToastType(TOAST_TYPE.DANGER), errorMessage, error.message));
        callback && (await callback(false, null));
    }
};

export const performAPIAction = async ({
    apiCall,
    errorMessageId,
    intl,
    setLoading,
    checkSession,
    addToastMessage,
    callback,
    entityType = null,
    successMessageId = ''
}) => {
    let result = null;
    apiCall &&
        (await callAPI(() => apiCall, {
            successMessage: successMessageId
                ? intl.formatMessage({
                      id: successMessageId
                  })
                : null,
            errorMessage: intl.formatMessage({
                id: errorMessageId
            }),
            callback: async (isSuccess, data) => {
                result = data;
                callback && (await callback(isSuccess, data, entityType));
            },
            setLoading,
            checkSession,
            addToastMessage
        }));

    return result;
};
