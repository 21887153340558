import { isAuthorisedProfile } from '../components/auth/UserHelper';
import { PROFILE } from '../types/types';
import {
    engagementClientCompanyRepository,
    engagementRepository,
    serializeEngagement,
    serializeEngagementReporting
} from '../kit/repository';
import { performAPIAction, callAPI, withSuccessResult, withSuccessResultFunctional } from './BaseService';
import { ENGAGEMENT_MEMBER_ROLE } from '../components/engagement/InitEngagement';
import { cleanFilters } from '../helpers/EngagementHelper';
import { IntlShape } from 'react-intl';
import { ServiceResult } from '../kit/network/ServiceResult';

/** Manage user engagement right **/
export const hasEditionRights = (engagement, user) => {
    if (isAuthorisedProfile(PROFILE.ADMIN, user?.profil)) {
        return true;
    }

    const member =
        engagement && Object.values(engagement?.appUserEngagements).find((x: any) => x.email === user?.email);
    return member && member?.engagementRole === ENGAGEMENT_MEMBER_ROLE.ADMIN;
};

/**
 auditor can delete other auditors
 auditor can not delete a manager
 manager can delete auditors and managers
 manager can not delete the last manager
 */
export const hasDeletionRights = (engagement, user, currentMember) => {
    const managers = Object.values(engagement?.appUserEngagements).filter(
        (x: any) => x.engagementRole === ENGAGEMENT_MEMBER_ROLE.ADMIN
    );
    return (
        (managers?.length > 1 && hasEditionRights(engagement, user)) ||
        currentMember.engagementRole !== ENGAGEMENT_MEMBER_ROLE.ADMIN
    );
};

interface ServiceProps {
    filters: Record<string, any>;
    intl: IntlShape;
    setLoading: (loading: boolean) => void;
    checkSession: (result: ServiceResult) => void;
    addToastMessage: (toast: any) => void;
    callback: null | ((isSuccess: boolean, data: any) => void);
}

/**
 * Get list of engagements for connected user.
 * Params filter available.
 *
 * @param {*} myThis
 * @param {*} filters
 * @returns
 */
export const fetchAll = async ({ filters, ...rest }: ServiceProps) => {
    let engagementFilters = {};
    // don't send the filters which are null
    filters &&
        Object.keys(filters).map(key => (filters[key] !== null ? (engagementFilters[key] = filters[key]) : null));

    return await performAPIAction({
        ...rest,
        apiCall: engagementRepository.fetchAll(engagementFilters),
        errorMessageId: 'FETCH.ENGAGEMENT.LIST.ACCESS.ERROR'
    });
};

/**
 * Get an engagement by Id.
 * @public
 */
export const fetchEngagement = async myThis => {
    const {
        intl,
        match: { params },
        checkSession
    } = myThis.props;
    const result = await engagementRepository.fetch(params.engagementId);
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            engagement: result.parsedValue,
            engagementClients: result.parsedValue.clientCompanies
                ? Object.values(result.parsedValue.clientCompanies)
                : []
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    sessionStatus: result.status,
                    technicalMessage: result?.error,
                    errorMessage: intl.formatMessage({ id: 'FETCH.ENGAGEMENT.USER.ACCESS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

/**
 * Get list engagements available as a selection choice to affect documents pending.
 * @public
 */
export const fetchMenuEngagements = async (myThis, filters = {}) => {
    const { intl, checkSession } = myThis.props;
    const result = await engagementRepository.fetchMenu(filters);
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        { menuEngagements: !!result ? Object.values(result?.parsedValue) : undefined },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    sessionStatus: result?.status,
                    technicalMessage: result?.error,
                    errorMessage: intl.formatMessage({ id: 'FETCH.ENGAGEMENT.MENU.ACCESS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

export const fetchMenuEngagementsFunctional = async (setState, state, intl, checkSession, filters = {} ) => {
    const result = await engagementRepository.fetchMenu(filters);
    const sessionStatus = await checkSession(result);

    withSuccessResultFunctional(
        setState,
        sessionStatus,
        result,
        { menuEngagements: !!result ? Object.values(result?.parsedValue) : undefined },
        {
            checkSessionResults: [
                ...state.checkSessionResults,
                {
                    sessionStatus: result?.status,
                    technicalMessage: result?.error,
                    errorMessage: intl.formatMessage({ id: 'FETCH.ENGAGEMENT.MENU.ACCESS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

/**
 * Get list of confirmations for engagement selected.
 */
export const fetchEngagementConfirmations = async ({
    filters,
    engagementId,
    checkSession,
    addToastMessage,
    successMessage = '',
    errorMessage,
    setLoading,
    callback,
}) => {
    const confirmationsFilters = cleanFilters(filters);
    const apiMethodCall = () => engagementRepository.fetchConfirmations(engagementId, confirmationsFilters);
    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage,
        errorMessage,
        callback
    });
};

/**
 * Get list of confirmations for engagement selected.
 */
export const fetchEngagementItems = async (myThis, filters = {}) => {
    const {
        intl,
        match: { params },
        checkSession
    } = myThis.props;
    const result = await engagementRepository.fetchItems(params.engagementId, filters);
    const sessionStatus = await checkSession(result);

    /** Extract childItems */
    let resultItems = result?.parsedValue?.items;
    if (resultItems)
        Object.values(resultItems)?.forEach((item: any) => item?.childItems?.forEach(child => resultItems.push(child)));

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            itemsPaginated: { ...result?.parsedValue, ...{ items: resultItems } }
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    sessionStatus: result.status,
                    technicalMessage: result?.error,
                    errorMessage: intl.formatMessage({ id: 'FETCH.ENGAGEMENT.ITEMS.ACCESS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

/**
 * Get client companies registered for a selected engagement by Id.
 * @public
 */
export const fetchEngagementClients = async myThis => {
    const {
        intl,
        match: { params },
        checkSession
    } = myThis.props;

    const result = await engagementClientCompanyRepository.fetchByEngagement(params.engagementId);
    const sessionStatus = await checkSession(result);

    withSuccessResult(
        myThis,
        sessionStatus,
        result,
        {
            clientCompanies: Object.values(result.parsedValue)
        },
        {
            checkSessionResults: [
                ...myThis.state.checkSessionResults,
                {
                    sessionStatus: result.status,
                    technicalMessage: result?.error,
                    errorMessage: intl.formatMessage({ id: 'FETCH.COMPANY.CLIENTS.ACCESS.ERROR' })
                }
            ]
        }
    );

    return sessionStatus;
};

export const fetchEngagementThirdParties = async ({
    checkSession,
    addToastMessage,
    successMessage,
    errorMessage,
    setLoading,
    callback,
    engagementId
}) => {
    const apiMethodCall = () => engagementRepository.fetchThirdPartyCompanies(engagementId);

    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage: successMessage,
        errorMessage: errorMessage,
        callback: callback
    });
};

export const createEngagement = async ({
    checkSession,
    addToastMessage,
    successMessage,
    errorMessage,
    setLoading,
    callback,
    engagement
}) => {
    const apiMethodCall = () => engagementRepository.create(serializeEngagement(engagement));
    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage: successMessage,
        errorMessage: errorMessage,
        callback: callback
    });
};

export const deleteEngagement = async ({
    checkSession,
    addToastMessage,
    successMessage,
    errorMessage,
    setLoading,
    callback,
    engagementInfo
}) => {
    const apiMethodCall = () => engagementRepository.delete(engagementInfo.id, engagementInfo.comment);
    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage: successMessage,
        errorMessage: errorMessage,
        callback: callback
    });
};

export const updateEngagement = async ({
    engagement,
    checkSession,
    addToastMessage,
    successMessage,
    errorMessage,
    setLoading,
    callback,
}) => {
    const apiMethodCall = () => engagementRepository.update(engagement.id, serializeEngagement(engagement));
    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage: successMessage,
        errorMessage: errorMessage,
        callback: callback
    });
};

export const downloadFiles = async ({
    downloadType,
    engagementId,
    checkSession,
    addToastMessage,
    successMessage,
    errorMessage,
    setLoading,
    callback,
}) => {
    const apiMethodCall = () =>
        engagementRepository.downloadFiles(engagementId, serializeEngagementReporting(downloadType));
    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage: successMessage,
        errorMessage: errorMessage,
        callback: callback
    });
};

export const toggleEngagementArchiveStatus = async ({
    engagementId,
    checkSession,
    addToastMessage,
    successMessage,
    errorMessage,
    setLoading,
    callback,
}) => {
    // @ts-ignore
    const apiMethodCall = () => engagementRepository.toggleArchiveStatus(engagementId);
    await callAPI(apiMethodCall, {
        setLoading,
        checkSession,
        addToastMessage,
        successMessage: successMessage,
        errorMessage: errorMessage,
        callback: callback
    });
};
